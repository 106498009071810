.infodiv{
    height: 60px;
    padding-left: 30px;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.8);
    
}

.colorline1{
    background-color: #4c0c54;
}
.colorline2{
    background-color: #561441;
}
.colorline3{
    background-color: #931636;
}
.colorline4{
    background-color: #C71D24;
}
.colorline5{
    background-color: #E84922;
}
.colorline6{
    background-color: #EF7A2B;
}

.icons{
    padding-right: 10px;
}

.logosection{
    height: 300px;

}

.logo{
    width: 200px;
    padding: 30px;
}

.infoimage{
    width: 100%;
}

/* unvisited link */
a:link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
  }
  
  /* visited link */
  a:visited {
    color: #fff;
    text-decoration: none;
  }
  
  /* mouse over link */
  a:hover {
    color: #fff !important;;
    text-decoration: none !important;; 
  }
  
  /* selected link */
  a:active {
    color: #fff;
    text-decoration: none;
  }


  @media only screen and (max-width: 731px) {
    .infodiv {
        font-size: 18px;
        padding-top: 16px;
    }

    .icons{
       font-size: 40px;
    }

    .logosection{
        height: 190px;
    }

    .mybtn{
        display: none;
    }

  
    
  }